<!-- ErrorBoundary.vue -->
<template>
  <slot v-if="!error || isProduction"></slot>
  <div v-else class="flex h-full items-center justify-center bg-gray-100">
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Oops! Something went wrong.
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          We're sorry for the inconvenience. Please try refreshing the page or
          contact support if the problem persists.
        </p>
      </div>
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg
              class="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">Error Details</h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          @click="reload"
          class="group btn-primary relative flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white"
        >
          Refresh Page
        </button>
      </div>
    </div>
  </div>

  <!-- Toast -->
  <ToastProvider>
    <ToastRoot
      v-if="error && isProduction"
      class="bg-white rounded-md shadow-lg p-4 items-center data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full fixed bottom-4 right-4 left-4 md:left-auto md:top-4 md:max-w-sm border"
    >
      <ToastTitle class="text-sm font-medium text-red-600 mb-1">
        An error occurred
      </ToastTitle>
      <ToastDescription class="text-sm text-gray-500">
        <div class="font-medium mb-1">Error details:</div>
        <div class="font-mono text-xs bg-gray-50 p-2 rounded-md overflow-auto max-h-[25vh] md:max-h-32">
          {{ error }}
        </div>
        <div class="mt-3 flex justify-end">
          <button
            @click="reload"
            class="inline-flex items-center px-3 py-2 md:py-1.5 text-sm md:text-xs font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <svg class="w-4 h-4 md:w-3 md:h-3 mr-2 md:mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
            </svg>
            Refresh Page
          </button>
        </div>
      </ToastDescription>
      <ToastClose class="absolute top-2 right-2">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
        </svg>
      </ToastClose>
    </ToastRoot>
  </ToastProvider>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue';
import {
  ToastProvider,
  ToastRoot,
  ToastTitle,
  ToastDescription,
  ToastClose,
} from 'radix-vue';

const error = ref<string | null>(null);
const isProduction = process.env.NODE_ENV === 'production';

onErrorCaptured((err: Error, instance: unknown, info: string) => {
  error.value = `${err.toString()}\nInfo: ${info}`;
  console.error(err);
  return false; // Prevent the error from propagating further
});

const reload = (): void => {
  window.location.reload();
};
</script>
