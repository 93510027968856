declare global {
  interface ImportMeta {
    env: Record<string, string>;
  }
}

import posthog from 'posthog-js';
import mixpanel from 'mixpanel-browser';

const isProduction = !window.location.host.includes('127.0.0.1') 
  && !window.location.host.includes('localhost')
  && process.env.NODE_ENV === 'production'
  && !window.location.host.includes('vercel.app')

if (isProduction) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, { api_host: 'https://us.posthog.com' })
}


/**
 * Identifies the user by the provided userId.
 *  Example: Generic_Org_User::45f89a2b-3cab-59db-a397-6831ce9746e4
 * 
 * @param userId - The unique identifier for the user.
 * @param options - Options for the identify call. You can include an 'email' field in this object for PostHog.
 */
const identifyUser = (userId: string, options: object = {}) => {
    if (!isProduction) return;
    let splittedList = userId.split('::')
    let idForMixpanel = splittedList[splittedList.length - 1]
    posthog.identify(userId, options);
    mixpanel.identify(idForMixpanel);
}

/**
 * Sets user properties in both PostHog and Mixpanel.
 * 
 * @param properties - An object containing user properties to set.
 * 
 * Note: You need to include '$email' in the properties object to set the email in Mixpanel.
 * Example:
 * {
 *   $email: 'user@example.com',
 *   $name: 'John Doe',
 *   customProperty: 'value'
 * }
 */
const setUserProperties = (properties: object) => {
    if (!isProduction) return;
    mixpanel.people.set(properties);
    posthog.people.set(properties);
}

const trackEvent = (event: string, properties: object={}) => {
    if (!isProduction) return;
    posthog.capture(event, properties);
    mixpanel.track(event, properties);
}


/**
 * Resets both PostHog and Mixpanel analytics.
 * Should be called after the user has signed out.
 */
const resetAnalytics = () => {
    if (!isProduction) return;
    posthog.reset();
    mixpanel.reset();
}

export {trackEvent, identifyUser, setUserProperties, resetAnalytics};
